.wrapper {
	height: 53px;
	margin-right: 15px;
}

.menuIcon {
	font-size: 25px;
	color: #a0a0a0;
	opacity: 0.9;
}

.wrapper .menuButton:active,
.wrapper .menuButton:focus {
	border: none;
}

.menuList {
	font-size: 20px;
}

@media only screen and (min-width: 700px) {
	.wrapper {
		display: none;
	}
}

@media only screen and (max-width: 700px) {
	.wrapper {
		height: 100%;
		margin-right: 0px;
		display: flex;
		align-items: center;
	}
}

