.link {
    text-decoration: underline;
    color: blue;
}

.link:hover {
    text-decoration: underline;
}

.li {
    margin-bottom: 5px;
}

.link:visited {
    color: brown;
}

.boldMainTitle {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    text-align: center;
}

.boldTitle {
    font-weight: 600;
    font-size: 16px;
    margin-left: 20px;
}

.bold {
    font-weight: 600;
}