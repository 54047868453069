.callout {
  background: #e3edf2;
  border-left: 4px solid #5bc0de;
  padding: 0.1rem 1rem;
  margin: 0;
  margin-bottom: 1rem
}

.callout .title {
  color: #46b8da;
  position: relative;
  margin-left: 1.33rem;
}

.calloutIcon {
  position: absolute;
  left: -1.6rem;;
}

.link {
  color: #5c46ff;
  text-decoration: underline;
}

.calloutLink {
  margin-left: 1.33rem;
}

.list {
  padding-left: 20px;
  
}
.list li {
  margin-bottom: 0.5rem;
}
