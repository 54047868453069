.dropdownContnet {
    display: flex;
    gap: 5px;
    cursor: pointer;
    margin-left: 170px;
}

.titleModal {
    color: #0D0D0D;
    font-size: 18px;
    font-weight: 700;
}

.buttons {
    display: flex;
    margin-top: 40px;
    width: 100%;
    gap: 12px;
}

.button {
    height: 45px;
    width: 100%;
}

.formContainer {
    margin-top: 24px;
}

.label {
    font-size: 15px;
    color: #333;
    font-weight: 700;
}

.previewLabel {
    display: inline-block;
    width: 100%;
    color: #333;
    font-size: 15px;
    font-weight: 600;
}

.previewBaseUrl {
    font-size: 14px;
    font-weight: 400;
    color: #999;
}

.previewInput {
    font-size: 14px;
    font-weight: 400;
    color: #4D4D4D;
}

.error {
    margin-top: -20px;
    margin-bottom: 20px;
    color: #F04438;
}

.success {
    margin-top: -20px;
    margin-bottom: 20px;
    color: #109121;
}

.info {
    margin-top: 14px;
}

.greenInput {
    border-color: #109121 !important;
}

.paragraph {
    margin: 0px;
    padding: 0px;
}

.alertTitle {
    margin-bottom: 3px;
    margin-left: 10px;
    color: #333;
    font-size: 15px;
    font-weight: 600;
}

.alertURL {
    margin-left: 10px;
    background: #F1F1F1;
    padding: 8px 6px ;
    border-radius: 6px;
}

.sceneAlias {
    display: inline-block;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 1445px) {
	.dropdownContnet {
        margin-left: 50px;
    }
}