.container {
	height: 100%;
}

.root {
	display: flex;
	align-items: center;
	padding-left: 10vw;
	padding-right: 10vw;
	max-width: 2560px;
	margin-left: auto;
	margin-right: auto;
}

.zepeto {
	height: 20px;
	font-weight: 700;
}

.logoWrapper {
	display: flex;
	align-items: center;
}

.webBuilder {
	display: flex;
	align-items: center;
	border-radius: 6px;
	padding: 6px;
	height: 20px;
	background-color: rgba(51, 51, 51, 1);
	color: #fff;
	font-size: 12px;
	min-width: 83px;
}

.ozoneBlock {
	align-items: center;
	margin-right: 20px;
}

.zepetoText {
	font-size: 10px;
	color: rgba(51, 51, 51, 1);
	line-height: 0;
}

.rootEditor {
	display: flex;
	align-items: center;
	max-width: 2560px;
	padding-left: 1vw;
	padding-right: 1vw;
}

.profileCol {
	display: flex;
	flex-direction: column;
}

.exploreLink {
	opacity: 0.9;
	font-size: 16px;
	margin-left: 30px;
	cursor: pointer;
	color: rgba(13, 13, 13, 1);
}

.colExplore {
	display: flex;
	justify-content: center;
}

.headerButtons {
	color: #a0a0a0;
	opacity: 0.9;
	font-size: 19px;
}

.headerButtons:hover,
.headerButtons:focus:hover {
	color: #ffff;
}

.headerButtons:active {
	color: #ffff;
	opacity: 0.6;
}

.headerButtons:focus {
	color: #a0a0a0;
}

.divider {
	height: 40px;
	background-color: #a0a0a0;
	width: 1px;
	opacity: 0.8;
}

.userProfileWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.menuDrawer {
	display: none;
}

.editorButtons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 478px;
	margin-left: auto;
}

.homeButton {
	padding: 0px;
	padding-top: 5px;
	width: 32px;
	height: 32px;
	justify-content: center;
	border: 1px solid #ffd8ed;
	border-radius: 12px;
	margin-left: 25px;
}

.homeButton:hover,
.homeButton:active,
.homeButton:focus {
	border: none;
	background: #e02189;
}

.homeButton:hover .homeIcon,
.homeButton:focus .homeIcon,
.homeButton:active .homeIcon {
	fill: #ffffff;
}

.divider {
	height: 26px;
}

.appsWrapper {
	margin-left: 25px;
	margin-right: 25px;
	padding: 10px;
	height: 40px;
	cursor: pointer;
	border-radius: 100px;
}

.appsWrapper:hover {
	transition: 0.1s ease-in;
	background-color: #cacaca94;
}

.contentApps {
	display: flex;
	flex-direction: column;
	width: 255px;
	gap: 6px;
}

.left {
	margin-right: auto;
}

.appCard {
	display: flex;
	align-items: center;
	padding: 8px;
	border-radius: 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	cursor: pointer;
	color: #333333;
}

.appCard:hover {
	transition: 0.1s ease-in;
	background-color: #e8e8e8;
}

.appTitle {
	margin-bottom: 2px;
	font-weight: 700;
	font-size: 16px;
	line-height: 130%;
	color: #333333;
}

.appSubtitle {
	font-weight: 400;
	font-size: 12px;
	line-height: 130%;
	color: #666666;
}

.appTextWrapper {
	margin-left: 12px;
}

.authButtons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	justify-items: flex-end;
}

.signInButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 45px;
	border: 1px solid #000000;
	background-color: transparent;
	filter: drop-shadow(0px 0px 80px rgba(5, 5, 5, 0.4));
	border-radius: 12px;
	font-size: 16px;
	padding: 12px 24px;
	color: #000000;
}

.changeAlias {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdownWrapper {
	height: 32px;
}

.zepetoWrapper {
	cursor: pointer;
}

.unityLogo {
	height: 60px;
	object-fit: contain;
}

.dropdownButton {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	background: #e20073;
	border: none;
	padding: 12px;
}

.dropdownButton:hover {
	background: #f92793;
}

.dropdownButton:active {
	background: #c40266;
}

.dropdownButton:focus {
	background: #ed3996;
}

.dropdownList {
	line-height: 30px;
}

.dropdownWrapper {
	display: flex;
	align-items: center;
	height: 100%;
	min-height: 50px;
	min-width: 50px;
	gap: 5px;
}

@media only screen and (max-width: 1000px) {
	.userProfileWrapper {
		padding-right: 5px;
	}

	.root {
		padding-left: 1vw;
		padding-right: 1vw;
	}
}

@media only screen and (max-width: 960px) {
	.zepetoWrapper {
		flex-direction: column;
		align-items: center;
	}

	.homeButton {
		margin-left: 0px;
		min-width: 40px;
	}

	.userProfile {
		margin-left: 0px;
	}
}

@media only screen and (max-width: 810px) {
	.editorButtons {
		justify-content: flex-end;
		width: 100%;
		margin-left: 0;
	}
}

@media only screen and (max-width: 700px) {
	.userProfileWrapper {
		padding-right: 10px;
	}

	.colExplore,
	.beCreatorButton,
	.userProfile {
		display: none;
	}

	.menuDrawer {
		display: flex;
		margin-right: 0;
		align-self: center;
		margin-left: 5px;
		justify-content: center;
	}

	.authButtons {
		display: none;
	}
}

@media only screen and (max-width: 960px) {
	.sceneNameWrapper {
		display: none;
	}
}

@media only screen and (max-width: 550px) {
	.search {
		width: 10px;
	}

	 .signInButton {
		padding: 6px 12px;
		height: 32px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 450px) {
	.authButtons {
		display: none;
	}
}

.canvasSize {
	max-height: 100vh;
	max-width: 100vw;
}
